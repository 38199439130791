<template>
  <common-dialog
    :show="show"
    :loading="loading"
    @close="close"
  >
    <template #header>
      <span>Ручное списание с платежного поручения</span>
    </template>
    <template #body>
      <v-row>
        <v-col cols="3">
          <label>Остаток по платежу</label>
          <input-numeric
            v-model="paymentOrder.remains"
            disabled
            background-color="grey lighten-2"
            :fraction="2"
            :suffix="paymentOrder.currency_letter"
          />
        </v-col>
        <v-col cols="9">
          <label>Вид платежа</label>
          <v-autocomplete
            :value="paymentOrder.code"
            disabled
            auto-select-first
            :items="paymentCodes"
            outlined
            dense
            item-text="search"
            item-value="code"
            hide-details="auto"
            background-color="grey lighten-2"
            :filter="filterBySearchField"
          />
        </v-col>
        <v-col cols="2">
          <label>Номер ПП</label>
          <v-text-field
            :value="paymentOrder.payment_doc_number"
            disabled
            outlined
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </v-col>
        <v-col cols="2">
          <label>Дата ПП</label>
          <custom-date-picker
            :value="paymentOrder.payment_date"
            outlined
            dense
            disabled
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </v-col>
        <v-col cols="4">
          <label>Способ уплаты</label>
          <v-autocomplete
            :value="paymentOrder.payment_type"
            auto-select-first
            :items="paymentModes"
            item-text="search"
            item-value="code"
            outlined
            dense
            disabled
            hide-details="auto"
            :filter="filterBySearchField"
            background-color="grey lighten-2"
          />
        </v-col>
        <v-col cols="4">
          <label>Плательщик (УНП/ЛН)</label>
          <v-text-field
            :value="paymentOrderOwner"
            outlined
            disabled
            dense
            hide-details="auto"
            background-color="grey lighten-2"
          />
        </v-col>
        <v-col
          cols="3"
        >
          <label>Сумма списания</label>
          <input-numeric
            v-model="state.sum"
            :error="!isSumValid"
            :fraction="2"
            :suffix="paymentOrder.currency_letter"
          />
        </v-col>
        <v-col cols="9">
          <label>Вид платежа</label>
          <v-autocomplete
            v-model="state.code"
            auto-select-first
            :items="paymentCodes"
            outlined
            dense
            item-text="search"
            item-value="code"
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
        </v-col>
        <v-col cols="4">
          <label>Способ уплаты</label>
          <v-autocomplete
            v-model="state.payment_type"
            auto-select-first
            :items="paymentModes"
            item-text="search"
            item-value="code"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            :filter="filterBySearchField"
          />
        </v-col>
        <v-col cols="8">
          <label>Примечание</label>
          <v-text-field
            v-model="state.remark"
            outlined
            dense
            hide-details="auto"
            background-color="white"
          />
        </v-col>
      </v-row>
    </template>
    <template #footer>
      <div class="d-flex justify-end mt-4">
        <v-btn
          elevation="0"
          color="#EDEDED"
          class="mr-2"
          @click="close"
        >
          Отмена
        </v-btn>
        <v-btn
          :disabled="!isSumValid"
          color="#5CB7B1"
          elevation="0"
          @click="submit"
        >
          Списать
        </v-btn>
      </div>
    </template>
  </common-dialog>
</template>
<script>
import closeOnEscapeMixin from "@/mixins/close-on-escape.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import {filterBySearchFieldMixin} from "@/mixins/catalogs";
import {mapGetters} from "vuex";
import {convertEmptyStringsToNull} from "@/helpers/objects";
import {paymentOrderDebitDefaultState} from "@/helpers/payment-order";
import CustomDatePicker from "@/components/shared/custom-date-picker.vue";
import CommonDialog from "@/components/shared/common-dialog.vue";
import {fetchPaymentOrdersInjector} from "@/helpers/injectors";


export default {
  components: {CommonDialog, CustomDatePicker, InputNumeric},
  mixins: [closeOnEscapeMixin, filterBySearchFieldMixin],
  inject: {
    fetchPaymentOrders: {
      from: fetchPaymentOrdersInjector
    }
  },
  props: {
    show: {
      required: true,
      type: Boolean
    },
    params: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      loading: false,
      state: paymentOrderDebitDefaultState(),
      payments: [],
      paymentCodes: [],
      banks: [],
    }
  },
  computed: {
    ...mapGetters({
      selected: "dt/getSelected",
      user: 'auth/getUser',
      paymentModes: "catalogs/getNsiPaymentModes"
    }),
    paymentOrder(){
      return this.params.selectedPaymentOrder
    },
    paymentOrderOwner(){
      return this.paymentOrder.personal_number ?? this.paymentOrder.unp
    },
    isSumValid() {
      if (this.state.payment_order_id) {
        return this.state.sum <= (this.paymentOrder?.remains || 0)
      }
      return false
    },
  },
  created() {
    this.setState()
    this.loadBanks()
    this.fetchNsiPaymentCodes()
  },
  methods: {
    loadBanks() {
      return this.$store.dispatch('catalogs/loadBankBIC').then((banks) => {
        this.banks = banks
        return banks;
      }).catch(() => [])
    },
    setLoading(loading) {
      this.loading = loading
    },
    setState() {
      this.state.payment_order_id = this.paymentOrder.id
      this.state.payment_type = this.paymentOrder.payment_type
      this.state.code = this.paymentOrder.code
    },
    fetchNsiPaymentCodes() {
      this.$store.dispatch('catalogs/fetchNsiPaymentTypes').then(res => {
        this.paymentCodes = res.data.list.map((i) => ({...i, search: `${i.code}-${i.name}`}))
      })
    },
    clearState() {
      Object.assign(this.$data.state, paymentOrderDebitDefaultState())
    },
    submit() {
      if (!this.state.payment_order_id) {
        this.$warning("Не выбрано ПП")
        return false;
      }
      if (!this.state.payment_type) {
        this.$warning("Отсутствует способ уплаты")
        return false;
      }
      if (!this.state.code) {
        this.$warning("Отсутствует вид платежа")
        return false;
      }
      const payload = convertEmptyStringsToNull(this.state)
      this.setLoading(true)
      this.$store.dispatch('dt/createPaymentOrderDebit', payload)
        .then(() => this.$info("Списание создано"))
        .then(() => this.fetchPaymentOrders())
        .then(() => this.close())
        .catch((err) => {
          const DEFAULT_MSG = "Не удалось создать списание"
          const msg = err.response.status === 400 ? err.response.data || DEFAULT_MSG : DEFAULT_MSG
          this.$error(msg)
        })
        .finally(() => this.setLoading(false))
    },
    close() {
      this.$emit('update:show', false)
    }
  }
}
</script>
